import CurrencyInputComponent from "../../components/currency-input.component";
import CardDiscountComponent from "../../components/card-discount.component";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import DropdownComponent from "../../components/dropdown.component";
import { doubleToCurrency, doubleToPercent } from "../../utils";
import { Logic } from "./logic";
import { useEffect } from "react";
import InputComponent from "../../components/input.component";

export default function Gas() {
    const { items, dropdownValue, value, setValue, verifyDiscount, handleBack, handleSubmit, getDiscountCard, getCashbackCard, enterAction, setDropDown, total, model, mounthDiscount } = Logic();
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <Container>
            <Row className="align-items-center">
                <Col className='col-lg-4 mt-4 mt-lg-0 order-1 order-lg-0'>
                    <Row className='mb-3 align-items-center'>
                        <Col className="col-auto">
                            <img src="/assets/img/icons/ic-calculadora.svg" alt="Ícone Calculadora" />
                        </Col >
                        <Col className="col-auto ms-2">
                            <img src="/assets/img/benefits/logo-ultragaz.svg" alt="Ultragaz" />
                        </Col>
                    </Row>
                    
                    <Row className='mb-4 page-title'>
                        <Col>
                            <h1 className="text-primary">
                                Quanto você gasta com
                                <br /> 
                                gás de cozinha?
                            </h1>
                        </Col>
                    </Row>

                    <Form>
                        <Row className="mb-3">
                            <Col>
                                <DropdownComponent
                                    title="Frequência de compra"
                                    value={dropdownValue ? items.find(x => x.key === dropdownValue)!.value : undefined}
                                    setDropdownValue={(value: number) => setDropDown(value)}
                                    items={items}
                                />
                                <InputComponent
                                    title="Valor do gás (R$)"
                                    type="text"
                                    placeholder="R$ 00,00"
                                    value={value}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(event.target.value)}
                                    onKeyUp={() => verifyDiscount()}
                                    disabled={dropdownValue === undefined || dropdownValue === 0}
                                    isValid={value !== undefined}
                                    isInvalid={false}
                                    enterKeyHint="enter"
                                    onKeyDown={enterAction}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <h4 className='title-detail'>
                                    Estimativa de economia 
                                </h4>
                            </Col>
                        </Row>
                        <Row className="pb-5">
                            <Col>
                                <table className='table-discounts w-100'>
                                    <tbody>
                                        <tr className='line-details'>
                                            <td className='info'>{doubleToPercent(model.items[dropdownValue === undefined || dropdownValue === 0 ? 0 : dropdownValue - 1]?.discount)} de desconto* </td>
                                            <td className='value text-end'>{doubleToCurrency(mounthDiscount)}</td>
                                        </tr>
                                        <tr className='line-total'>
                                            <td className='info'>Taxa de entrega</td>
                                            <td className='value text-end'>Grátis</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr className='line-total'>
                                            <td className='info'>Benefício mensal</td>
                                            <td className='value text-end'>{doubleToCurrency(total)}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </Col>
                        </Row>

                        <Row className="mt-3 align-items-center">
                            <Col>
                                <a className="text-dark" onClick={handleBack}><u>Voltar</u></a>
                            </Col>
                            <Col className="d-flex justify-content-end">
                                <Button variant="secondary" size="sm" type="button" onClick={handleSubmit}>Próximo</Button>
                            </Col>  
                        </Row>
                    </Form>
                </Col>
                <Col lg={4} className="mb-sm-0 d-flex order-0 order-lg-1 px-0">
                    <CardDiscountComponent discount={getDiscountCard()} cashback={getCashbackCard()} />
                </Col>
                <Col lg={4} className="d-none d-lg-block text-center order-2">
                    <img src="./assets/img/banners/banner-gas.png" alt="Banner Gás" className='img-fluid'/>
                </Col>
            </Row>
            <Row className='pt-5'>
                <Col lg={4}>
                    <p className='text-muted'>
                        <i>
                            * O desconto de 9% é aplicado automaticamente durante a compra quando você faz o pedido pelo app ou site e tem um limite de até 5 unidades por pedido. 
                            <br />
                            ** A disponibilidade de entrega na sua região/bairro é validada no ato da compra.
                            <br />
                            *** Em breve, filiados do Cartão de TODOS também rebecerão cashback (dinheiro de volta) sobre o valor da compra no app Cartão de TODOS.
                            <br />
                            **** Na estimativa de economia, o valor do benefício mensal pode variar  conforme a sua frequência de compra.

                        </i>
                    </p>
                </Col>
            </Row>
        </Container>
    );
}
