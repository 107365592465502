import { Button, Carousel, Col, Container, Form, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import CheckboxComponent from '../../components/checkbox.component';
import DropdownComponent from '../../components/dropdown.component';
import InputComponent from '../../components/input.component';
import InputMask from "react-input-mask";
import { focusOn } from '../../utils';
import { Logic } from './logic';
import { ModalTermosComponente } from "../../components/modal_termos.component";
import { useEffect } from 'react';
import { AuthService } from '../../../react-oauth2-pkce/AuthService';

export default function FormClient() {
    if(window.location.pathname === "/") {
        const authService = new AuthService({
            clientId: `${process.env.REACT_APP_SSO_CLIENT_ID}`,
            provider: `${process.env.REACT_APP_SSO_BASE_URL}/connect`,
            redirectUri: `${process.env.REACT_APP_SSO_REDIRECT_URI}`,
            scopes: ['openid', 'profile'],
            durableLogin: process.env.REACT_APP_SSO_DURABLE_LOGIN === 'true',
        });
        if(authService.isAuthenticated()) {
            window.location.href = "/colaborador";
        }
    }
    const {
        name, setName, verifyName, validName, invalidName, nameErrorMessage,
        registration, setRegistration, verifyRegistration, validRegistration, invalidRegistration, registrationErrorMessage,
        franchise, setFranchise, verifyFranchise, validFranchise, invalidFranchise, franchiseErrorMessage,
        attendantName, setAttendantName, verifyAttendantName, validAttendantName, invalidAttendantName, attendantNameErrorMessage,
        linkColaborator, setLinkColaborator,
        monthly,
        email, setEmail, verifyEmail, validEmail, invalidEmail, emailErrorMessage,
        cep, setCep, verifyCep, validCep, invalidCep, cepErrorMessage,
        phone, setPhone, verifyPhone, validPhone, invalidPhone, phoneErrorMessage,
        cpf, setCpf, verifyCpf, validCpf, invalidCpf, cpfErrorMessage,
        terms, setTerms, verifyTerms, invalidTerms, termsErrorMessage,
        termsWhatsapp, setTermsWhatsapp, termsSMS, setTermsSMS, termsEmail, setTermsEmail,
        // dropdownValue, setDropdownValue, items, showDropdownRequired,
        loadingCep, loadingEmail, loadingRegistration, loadingCPF, loadingSaveForm,
        modalShow, setModalShow, modalUpdatesShow, setModalUpdatesShow,
        handleSubmit,
        isColaborator, isColaboratorLead, colaboratorHasIdpv,
        loadingIdpv
    } = Logic();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
        <Container>
            <Row className="align-items-center">
                <Col sm={12} lg={6} className="mt-4">
                    <Row className='mb-3 align-items-center'>
                        <Col className="col-auto">
                            <img src="/assets/img/icons/ic-calculadora.svg" alt="Ícone Calculadora" />
                        </Col>
                    </Row>
                    <Row className='mb-4 page-title'>
                        <Col>
                            <h1 className="text-primary mb-3">
                                Calculadora de Descontos
                            </h1>
                            <p className="fw-normal mb-3">
                                Calcule agora os seus gastos com saúde, <br className='d-block d-lg-none' />educação, 
                                <br className='d-none d-lg-block' />
                                lazer e muito mais. Veja quanto <br className='d-block d-lg-none' />você pode economizar 
                                <br className='d-none d-lg-block' />
                                no dia a dia com o <br className='d-block d-lg-none' />Cartão de TODOS. 
                            </p>
                            <i className="text-muted d-lg-block d-md-none">
                                *Campos obrigatórios
                            </i>
                        </Col>
                    </Row>

                    <Form>
                        {isColaborator ?
                        <>
                        <Row>
                            <Col sm={12} lg={6}>
                                <InputComponent
                                    autoFocus={true}
                                    title="CPF"
                                    type="text"
                                    placeholder="123.456.78"
                                    value={cpf}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setCpf(value => value = event.target.value)}
                                    onBlur={verifyCpf}
                                    isValid={validCpf}
                                    isInvalid={invalidCpf}
                                    enterKeyHint="next"
                                    onKeyDown={(event: React.KeyboardEvent<Element>) => focusOn(event, "CEP")}
                                    as={InputMask}
                                    mask="999.999.999-99"
                                    errorMessage={cpfErrorMessage} 
                                    loading={loadingCPF}
                                    notice={isColaboratorLead ? "CPF não cadastrado no Cartão de TODOS" : ''}
                                    required />
                            </Col>
                            {!isColaboratorLead ? 
                            <Col sm={12} lg={6}>
                                <InputComponent
                                    title="Matrícula"
                                    type="text"
                                    placeholder="MG000000000"
                                    upperCase={true}
                                    value={registration}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setRegistration(value => value = event.target.value)}
                                    onBlur={verifyRegistration}
                                    isValid={validRegistration}
                                    isInvalid={invalidRegistration}
                                    enterKeyHint="next"
                                    onKeyDown={(event: React.KeyboardEvent<Element>) => focusOn(event, "Nome completo")}
                                    as={InputMask}
                                    mask="aa999999999"
                                    errorMessage={registrationErrorMessage}
                                    loading={loadingRegistration}
                                    required />
                            </Col>
                            :
                            <></>
                            }
                            <Col sm={12} lg={6}>
                                <InputComponent
                                    title="Nome completo"
                                    type="text"
                                    placeholder="ex. Maria Lucia Almeida"
                                    value={name}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setName(value => value = event.target.value)}
                                    onBlur={verifyName}
                                    isValid={validName}
                                    isInvalid={invalidName}
                                    enterKeyHint="next"
                                    onKeyDown={(event: React.KeyboardEvent<Element>) => focusOn(event, "CPF")}
                                    errorMessage={nameErrorMessage}
                                    required />
                            </Col>
                            <Col sm={12} lg={6}>
                                <InputComponent
                                    title="E-mail"
                                    type="email"
                                    placeholder="ana@gmail.com"
                                    value={email}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(value => value = event.target.value)}
                                    onBlur={verifyEmail}
                                    isValid={validEmail}
                                    isInvalid={invalidEmail}
                                    enterKeyHint="next"
                                    onKeyDown={(event: React.KeyboardEvent<Element>) => focusOn(event, "Telefone")}
                                    errorMessage={emailErrorMessage}
                                    required />
                            </Col>
                            <Col sm={12} lg={6}>
                                <InputComponent
                                    title="CEP"
                                    type="text"
                                    placeholder="00000-000"
                                    value={cep}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setCep(value => value = event.target.value)}
                                    onBlur={() => {verifyCep(cep)}}
                                    isValid={validCep}
                                    isInvalid={invalidCep}
                                    enterKeyHint="next"
                                    onKeyDown={(event: React.KeyboardEvent<Element>) => focusOn(event, "Telefone")}
                                    as={InputMask}
                                    mask="99999-999"
                                    errorMessage={cepErrorMessage}
                                    loading={loadingCep}
                                    required />
                            </Col>
                            <Col sm={12} lg={6}>
                                <InputComponent
                                    title="Telefone"
                                    type="text"
                                    placeholder="(11) 99999-9999"
                                    value={phone}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPhone(value => value = event.target.value)}
                                    onBlur={verifyPhone}
                                    isValid={validPhone}
                                    isInvalid={invalidPhone}
                                    enterKeyHint="next"
                                    onKeyDown={(event: React.KeyboardEvent<Element>) => focusOn(event, "Nome do atendente")}
                                    as={InputMask}
                                    mask="(99) 99999-9999"
                                    errorMessage={phoneErrorMessage}
                                    required />
                            </Col>
                            <Col sm={12} lg={6}>
                                <InputComponent
                                    title="Nome do atendente"
                                    type="text"
                                    placeholder="ex. Maria Lucia de Almeida"
                                    value={attendantName}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setAttendantName(value => value = event.target.value)}
                                    onBlur={verifyAttendantName}
                                    isValid={validAttendantName}
                                    isInvalid={invalidAttendantName}
                                    enterKeyHint="next"
                                    onKeyDown={(event: React.KeyboardEvent<Element>) => focusOn(event, "Termos")}
                                    errorMessage={attendantNameErrorMessage}
                                    disabled={true}
                                    required />
                            </Col>
                            {!isColaboratorLead ?
                            <Col sm={12} lg={6}>
                                <InputComponent
                                    title="Franquia"
                                    type="text"
                                    placeholder="ex. São Miguel"
                                    value={franchise}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFranchise(value => value = event.target.value)}
                                    onBlur={verifyFranchise}
                                    isValid={validFranchise}
                                    isInvalid={invalidFranchise}
                                    enterKeyHint="next"
                                    onKeyDown={(event: React.KeyboardEvent<Element>) => focusOn(event, "Matrícula")}
                                    errorMessage={franchiseErrorMessage}
                                    disabled={true}
                                    required />
                            </Col>
                            :
                            <></>
                            }
                            {colaboratorHasIdpv ?
                            <Col sm={12}>
                                <InputComponent
                                    title="Link de venda"
                                    type="text"
                                    placeholder="ex. https://solicite.cartaodetodos.com.br/?idpv=..."
                                    value={linkColaborator}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setLinkColaborator(value => value = event.target.value)}
                                    onBlur={verifyAttendantName}
                                    isValid={validAttendantName}
                                    isInvalid={invalidAttendantName}
                                    enterKeyHint="next"
                                    onKeyDown={(event: React.KeyboardEvent<Element>) => focusOn(event, "Termos")}
                                    loading={loadingIdpv}
                                    textToCopy={linkColaborator} />
                            </Col>

                            :
                            <></>
                            }
                        </Row>
                        </>
                        :
                        <>
                        <Row>
                            <Col sm={12} lg={6}>
                                <InputComponent
                                    autoFocus={true}
                                    title="Nome completo"
                                    type="text"
                                    placeholder="ex. Maria Lucia Almeida"
                                    value={name}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setName(value => value = event.target.value)}
                                    onBlur={verifyName}
                                    isValid={validName}
                                    isInvalid={invalidName}
                                    enterKeyHint="next"
                                    onKeyDown={(event: React.KeyboardEvent<Element>) => focusOn(event, "E-mail")}
                                    errorMessage={nameErrorMessage}
                                    required />
                            </Col>
                            <Col sm={12} lg={6}>
                                <InputComponent
                                    title="E-mail"
                                    type="email"
                                    placeholder="ana@gmail.com"
                                    value={email}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(value => value = event.target.value)}
                                    onBlur={verifyEmail}
                                    isValid={validEmail}
                                    isInvalid={invalidEmail}
                                    enterKeyHint="next"
                                    onKeyDown={(event: React.KeyboardEvent<Element>) => focusOn(event, "CEP")}
                                    errorMessage={emailErrorMessage}
                                    loading={loadingEmail}
                                    required />
                            </Col>
                            <Col sm={12} lg={6}>
                                <InputComponent
                                    title="CEP"
                                    type="text"
                                    placeholder="00000-000"
                                    value={cep}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setCep(value => value = event.target.value)}
                                    onBlur={() => {verifyCep(cep)}}
                                    isValid={validCep}
                                    isInvalid={invalidCep}
                                    enterKeyHint="next"
                                    onKeyDown={(event: React.KeyboardEvent<Element>) => focusOn(event, "Telefone")}
                                    as={InputMask}
                                    mask="99999-999"
                                    errorMessage={cepErrorMessage}
                                    loading={loadingCep}
                                    required />
                            </Col>
                            <Col sm={12} lg={6}>
                                <InputComponent
                                    title="Telefone"
                                    type="text"
                                    placeholder="(11) 99999-9999"
                                    value={phone}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPhone(value => value = event.target.value)}
                                    onBlur={verifyPhone}
                                    isValid={validPhone}
                                    isInvalid={invalidPhone}
                                    enterKeyHint="next"
                                    onKeyDown={(event: React.KeyboardEvent<Element>) => focusOn(event, "CPF")}
                                    as={InputMask}
                                    mask="(99) 99999-9999"
                                    errorMessage={phoneErrorMessage}
                                    required />
                            </Col>
                            <Col sm={12} lg={6}>
                                <InputComponent
                                    title="CPF"
                                    type="text"
                                    placeholder="123.456.78"
                                    value={cpf}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setCpf(value => value = event.target.value)}
                                    onBlur={verifyCpf}
                                    isValid={validCpf}
                                    isInvalid={invalidCpf}
                                    enterKeyHint="next"
                                    onKeyDown={(event: React.KeyboardEvent<Element>) => focusOn(event, "Termos")}
                                    as={InputMask}
                                    mask="999.999.999-99"
                                    errorMessage={cpfErrorMessage} />
                            </Col>
                        </Row>
                        </>
                        }
                        <Row>
                            <Col sm={12} className="mt-3">
                                <CheckboxComponent
                                    title="Termos"
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setTerms(value => value = event.target.checked)}
                                    value={terms}
                                    onBlur={verifyTerms}
                                    isInvalid={invalidTerms}
                                    customLabel={<>{isColaborator ? 'O cliente aceitou' : 'Li e aceito'} os <button type="button" onClick={() => setModalShow(true)} className="underline text-link-terms"><strong>termos e condições</strong></button></>}
                                    errorMessage={termsErrorMessage}
                                    required
                                />
                                <CheckboxComponent
                                    title="TermosWhatsapp"
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setTermsWhatsapp(value => value = event.target.checked)}
                                    value={termsWhatsapp}
                                    customLabel={<>{isColaborator ? 'O cliente aceitou' : 'Eu aceito'} contato pelo <strong>Whatsapp</strong>.</>}
                                />
                                <CheckboxComponent
                                    title="TermosSMS"
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setTermsSMS(value => value = event.target.checked)}
                                    value={termsSMS}
                                    customLabel={<>{isColaborator ? 'O cliente aceitou' : 'Eu aceito'} notificações e contato via <strong>SMS</strong>.</>}
                                />
                                <CheckboxComponent
                                    title="TermosEmail"
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setTermsEmail(value => value = event.target.checked)}
                                    value={termsEmail}
                                    customLabel={<>{isColaborator ? 'O cliente aceitou' : 'Eu aceito'} notificações e contato via <strong>E-mail</strong>.</>}
                                />
                                <Button className="mt-3" variant="secondary" size="sm" type="button" disabled={loadingCep || loadingRegistration || loadingSaveForm} onClick={handleSubmit}>
                                    <span className="d-flex align-items-center">
                                        Iniciar
                                        {loadingSaveForm ? 
                                            <Spinner variant="light" size="sm" animation="border" role="status" className="ms-2">
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                            :
                                            <></>
                                        }
                                    </span>
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col lg={'auto'} className="ms-auto d-none d-xl-block">
                    <Carousel
                        fade
                        wrap={true}
                        indicators={true}
                        controls={false}
                        interval={3000}
                        pause={'hover'}
                    >
                        <Carousel.Item>
                            <Row className="h-100 align-items-center">
                                <img
                                    className="d-block"
                                    src="/assets/img/carousel/carousel-energia.png"
                                    alt="Energia de TODOS"
                                />
                                <Col>
                                    <p className="ps-4">
                                        <strong>Energia de TODOS</strong>
                                        <br />
                                        12% de cashback 
                                        <br />
                                        no aplicativo!
                                    </p>
                                </Col>
                            </Row>
                        </Carousel.Item>
                        <Carousel.Item>
                            <Row className="align-items-center">
                                <img
                                    className="d-block"
                                    src="/assets/img/carousel/carousel-farmacia.png"
                                    alt="Desconto em medicamentos"
                                />
                                <Col>
                                    <p className="ps-4">
                                        Descontos de até
                                        <br />
                                        35% na <strong>Droga Raia</strong>
                                        <br />
                                        e na <strong>Drogasil</strong> com
                                        <br />
                                        dinheiro de volta!
                                    </p>
                                </Col>
                            </Row>
                        </Carousel.Item>
                        <Carousel.Item>
                            <Row className="align-items-center">
                                <img
                                    className="d-block"
                                    src="/assets/img/carousel/carousel-gas.png"
                                    alt="Desconto no butijão de gás da Ultragaz"
                                />
                                <Col>
                                    <p className="ps-4">
                                        <strong>Desconto de 9%</strong>
                                        <br />
                                        no botijão de gás
                                        <br />
                                        <strong>Ultragaz</strong> pelo app
                                        <br />
                                        Cartão de TODOS!
                                    </p>
                                </Col>
                            </Row>
                        </Carousel.Item>
                        <Carousel.Item>
                            <Row className="align-items-center">
                                <img
                                    className="d-block"
                                    src="/assets/img/carousel/carousel-refuturiza.png"
                                    alt="Desconto em Cursos on-line Refuturiza"
                                />
                                <Col>
                                    <p className="ps-4">
                                        Vantagem irresistível!
                                        <br />
                                        Cursos on-line da 
                                        <br />
                                        <strong>Refuturiza</strong> por apenas 
                                        <br />
                                        <strong>{monthly}</strong>/mês.
                                    </p>
                                </Col>
                            </Row>
                        </Carousel.Item>
                        <Carousel.Item>
                            <Row className="align-items-center">
                                <img
                                    className="d-block"
                                    src="/assets/img/carousel/carousel-medico.png"
                                    alt="Desconto em consultas médicas e medicamentos"
                                />
                                <Col>
                                    <p className="ps-4">
                                        Consultas <strong>presenciais <br />ou on-line</strong> em todo o 
                                        <br />
                                        Brasil e medicamentos
                                        <br />
                                        com até <strong>35 % de <br />desconto</strong>.
                                    </p>
                                </Col>
                            </Row>
                        </Carousel.Item>
                    </Carousel>
                </Col>
            </Row>
        </Container>
        <ModalTermosComponente
            show={modalShow}
            mensalidadeCartao={localStorage.getItem("mensalidade_cartao")}
            onHide={() => setModalShow(false)}
        />
        <ModalTermosComponente
            show={modalUpdatesShow}
            onHide={() => setModalUpdatesShow(false)}
        />
        </>
    );
}